import { Drawer, DrawerBody, DrawerHeader } from '@/components/Drawer';
import { LoadingIndicator } from '@/components/loading-indicator';
import { Tabs, Tab } from '@/components/Tab';
import { DrawerHeadContainer } from '@/lib/styled';
import { CustomizeWorkspace } from '@/pages/home/icons.tsx';
import Addon from '@/pages/workgroup/form/setting/Addon';
import Advanced from '@/pages/workgroup/form/setting/Advanced';
import Alerts from '@/pages/workgroup/form/setting/Alerts';
import General from '@/pages/workgroup/form/setting/General';
import Tigergraph from '@/pages/workgroup/form/setting/Tigergraph';
import { isStatusActive, WorkspaceT } from '@/pages/workgroup/type';
import { useQueryWorkspaceMeta } from '@/pages/workgroup/hook';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { useRef, useState } from 'react';

export default function WorkspaceSetting({
  workspace,
  isOpen,
  onClose,
}: {
  workspace: WorkspaceT;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [activeKey, setActiveKey] = useState(isStatusActive(workspace.status) ? 'General' : 'Advanced');
  const [css, theme] = useStyletron();
  const editConfigurationContainer = useRef<HTMLDivElement>(null);
  const metaQuery = useQueryWorkspaceMeta(workspace.platform, workspace.region, workspace.cloud_provider_id);
  const { workspaceTypes = [] } = metaQuery?.data || {};

  return (
    <Drawer isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <div ref={editConfigurationContainer}></div>
      <DrawerHeader className={css({ backgroundColor: theme.colors['background.primary'] })}>
        <DrawerHeadContainer>
          <CustomizeWorkspace />
          Workspace Configuration
        </DrawerHeadContainer>
      </DrawerHeader>
      <DrawerBody
        $style={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        {metaQuery.isLoading ? (
          <LoadingIndicator />
        ) : (
          <Tabs
            activeKey={activeKey}
            onChange={({ activeKey }) => {
              setActiveKey(activeKey as string);
            }}
            activateOnFocus={false}
            overrides={{
              TabList: {
                style: {
                  backgroundColor: theme.colors['background.primary'],
                },
              },
            }}
          >
            <Tab title="General" key="General" disabled={!isStatusActive(workspace.status)}>
              <General workspace={workspace} onClose={onClose} workspaceTypes={workspaceTypes} />
            </Tab>
            <Tab title="Advanced Settings" key="Advanced">
              <Advanced workspace={workspace} onClose={onClose} workspaceTypes={workspaceTypes} />
            </Tab>
            <Tab title="Add-ons" key="Add-ons">
              <Addon configureContainer={editConfigurationContainer.current} workspace={workspace} onClose={onClose} />
            </Tab>
            <Tab title="Alerts" key="Alerts">
              <Alerts workspace={workspace} onClose={onClose} />
            </Tab>
            <Tab title="Graph Admin" key="Graph Admin" disabled={!isStatusActive(workspace.status)}>
              <Tigergraph workspace={workspace} onClose={onClose} />
            </Tab>
          </Tabs>
        )}
      </DrawerBody>
    </Drawer>
  );
}
