import eventemitter from '@/lib/eventEmitter';
import { StyledForm, StyledFormAction, StyledFormBody } from '@/pages/workgroup/form/setting/styled';
import { AdvancedType, Advanced } from '@/pages/workgroup/form/WorkSpaceSubForm';
import { useMutationUpdateWorkspace } from '@/pages/workgroup/hook';
import { UpdateWorkspaceRequest, WorkspaceT, WorkSpaceType } from '@/pages/workgroup/type';
import { getErrorMessage } from '@/utils/utils';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

export default function AdvancedForm({
  workspace,
  onClose,
  workspaceTypes,
}: {
  workspace: WorkspaceT;
  onClose: () => void;
  workspaceTypes: WorkSpaceType[];
}) {
  const form = useForm<AdvancedType>({
    defaultValues: {
      autoSuspend: workspace.auto_stop_minutes > 0,
      auto_stop_minutes: workspace.auto_stop_minutes || 10,
      enable_auto_start: workspace.enable_auto_start,
      enable_ha: workspace.enable_ha,
    },
  });

  const { handleSubmit } = form;

  const updateWorkSpaceMutation = useMutationUpdateWorkspace();

  const onUpdate = (data: AdvancedType) => {
    const { autoSuspend, auto_stop_minutes, enable_auto_start, enable_ha } = data;

    const updateRequest: UpdateWorkspaceRequest = {
      enable_auto_start,
      auto_stop_minutes: autoSuspend ? Number(auto_stop_minutes) : 0,
    };

    // check for ha
    if ((enable_ha && !workspace.enable_ha) || (!enable_ha && workspace.enable_ha)) {
      updateRequest.enable_ha = enable_ha;
    }

    const promise = updateWorkSpaceMutation.mutateAsync(
      {
        group_id: workspace.workgroup_id,
        space_id: workspace.workspace_id,
        data: updateRequest,
      },
      {
        onSuccess() {
          onClose();
        },
      }
    );

    toast.promise(
      promise,
      {
        loading: 'Updating workspace',
        success: (data) => 'Workspace updating request accepted.',
        error: (err) => `${getErrorMessage(err)}`,
      },
      {}
    );
  };

  const [showActions, setShowActions] = useState(true);
  useEffect(() => {
    eventemitter.on('scheduleFormOpened', () => {
      setShowActions(false);
    });
    eventemitter.on('scheduleFormClosed', () => {
      setShowActions(true);
    });
    return () => {
      eventemitter.off('scheduleFormOpened');
      eventemitter.off('scheduleFormClosed');
    };
  }, []);

  return (
    <StyledForm>
      <StyledFormBody>
        <Advanced form={form} workspace={workspace} workspaceTypes={workspaceTypes} />
      </StyledFormBody>
      {showActions && (
        <StyledFormAction>
          <Button type="button" kind="secondary" size="large" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="button"
            size="large"
            onClick={handleSubmit(async (data) => {
              onUpdate(data);
            })}
          >
            Save
          </Button>
        </StyledFormAction>
      )}
    </StyledForm>
  );
}
