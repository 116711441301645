import { TimezonePicker } from '@tigergraph/app-ui-lib/timezonepicker';
import { WorkspaceT, WorkSpaceType } from '@/pages/workgroup/type';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { Controller, FieldErrors, useForm, UseFormReturn } from 'react-hook-form';
import { FormControlOverrides, FormControlProps } from 'baseui/form-control';
import { FormControl as BaseFormControl } from '@tigergraph/app-ui-lib/form-control';
import { mergeOverrides } from 'baseui';
import { Overrides } from 'baseui/overrides';
import { CustomTheme } from '@tigergraph/app-ui-lib/Theme';
import { getErrorMessage } from '@/utils/utils';
import { showToast } from '@tigergraph/app-ui-lib/styledToasterContainer';
import { Schedule, ScheduleData, scheduleDescription, ScheduleWeekly, WeekDays } from './type';
import { roundTo30Minutes, useMutationAddSchedule, useMutationUpdateSchedule } from './hook';
import { expand } from 'inline-style-expand-shorthand';

import { Select } from '@tigergraph/app-ui-lib/select';
import ParagraphLabel from '@/components/PagagraphLabel';
import { formatPricePerHour } from '@/utils/format';
import currency from 'currency.js';
import { DatePicker } from '@tigergraph/app-ui-lib/datepicker';
import { TimePicker } from '@tigergraph/app-ui-lib/timepicker';
import { useEffect } from 'react';
import eventemitter from '@/lib/eventEmitter';
import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { StatefulTipsPopover } from '@/components/tipsPopover';
import { TRIGGER_TYPE } from 'baseui/popover';

export default function ScheduleForm({
  onClose,
  workspace,
  editSchedule,
  workspaceTypes,
}: {
  onClose: () => void;
  workspace: WorkspaceT;
  editSchedule?: Schedule;
  workspaceTypes: WorkSpaceType[];
}) {
  const mutationAdd = useMutationAddSchedule();
  const mutationEdit = useMutationUpdateSchedule();

  useEffect(() => {
    eventemitter.emit('scheduleFormOpened');
    return () => {
      eventemitter.emit('scheduleFormClosed');
    };
  }, []);

  const form = useForm<ScheduleData>({
    defaultValues: editSchedule
      ? editSchedule
      : {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          workspace_type_name: workspaceTypes[0].typeName,
          repeat: 'NOREPEAT',
          date: new Date(),
          time: roundTo30Minutes(new Date()),
          // @ts-ignore
          repeat_on: [],
        },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    getValues,
  } = form;

  const repeat = watch('repeat');

  const [css, theme] = useStyletron();

  const onCreate = (data: ScheduleData) => {
    mutationAdd.mutate(
      {
        group_id: workspace.workgroup_id,
        workspace_id: workspace.workspace_id,
        data,
      },
      {
        onSuccess() {
          onClose();
          showToast({
            kind: 'positive',
            message: `Schedule added successfully.`,
          });
        },
        onError(error) {
          showToast({
            kind: 'negative',
            message: `${getErrorMessage(error)}`,
          });
        },
      }
    );
  };

  const onUpdate = (data: ScheduleData) => {
    mutationEdit.mutate(
      {
        group_id: workspace.workgroup_id,
        workspace_id: workspace.workspace_id,
        id: editSchedule?.id!,
        data,
      },
      {
        onSuccess() {
          onClose();
          showToast({
            kind: 'positive',
            message: `Schedule updated successfully.`,
          });
        },
        onError(error) {
          showToast({
            kind: 'negative',
            message: `${getErrorMessage(error)}`,
          });
        },
      }
    );
  };

  const { orgQuota } = useWorkspaceContext();
  const quotaFilterIndex = orgQuota?.max_workspace_type
    ? workspaceTypes.findIndex((item) => item.typeName === orgQuota?.max_workspace_type)
    : -1;

  if (quotaFilterIndex > -1) {
    workspaceTypes = workspaceTypes.map((w, index) => ({
      ...w,
      disabled: index > quotaFilterIndex,
    }));
  }

  // for RO, do not allow resize
  if (!workspace.is_rw) {
    workspaceTypes = workspaceTypes.filter((w) => w.typeName === workspace.workspace_type.typeName);
  }

  const options = [
    {
      typeName: 'Pause',
    },
  ].concat(workspaceTypes);

  return (
    <div
      className={css({
        padding: '24px 16px 16px',
        backgroundColor: theme.colors['background.secondary'],
        borderRadius: '4px',
        border: `1px solid ${theme.colors['border.tertiary']}`,
      })}
    >
      <FormControl label="Timezone">
        <Controller
          control={control}
          name="timezone"
          render={({ field: { onChange, value, ...props } }) => (
            <TimezonePicker value={value} onChange={({ id }) => onChange(id)} {...props} size="compact" />
          )}
        />
      </FormControl>
      <div
        className={css({
          display: 'flex',
          gap: '8px',
        })}
      >
        <div
          className={css({
            width: '108px',
          })}
        >
          <FormControl label="Repeat">
            <Controller
              control={control}
              name="repeat"
              render={({ field: { value, onChange, ref, ...field } }) => {
                return (
                  <Select
                    size="compact"
                    options={[
                      {
                        id: 'NOREPEAT',
                        label: 'No Repeat',
                      },
                      {
                        id: 'DAILY',
                        label: 'Daily',
                      },
                      {
                        id: 'WEEKDAY',
                        label: 'Weekday',
                      },
                      {
                        id: 'WEEKLY',
                        label: 'Weekly',
                      },
                    ]}
                    value={[{ id: value }]}
                    labelKey="label"
                    onChange={(params) => {
                      onChange(params.value[0].id);
                    }}
                    clearable={false}
                    inputRef={ref}
                    {...field}
                    searchable={false}
                  />
                );
              }}
            />
          </FormControl>
        </div>
        {repeat === 'NOREPEAT' && <NoRepeat form={form} />}
        {(repeat === 'DAILY' || repeat === 'WEEKDAY') && <DailyOrWeekday form={form} />}
        {repeat === 'WEEKLY' && <Weekly form={form} />}
      </div>
      <FormControl label="Workspace Size">
        <Controller
          control={control}
          name="workspace_type_name"
          render={({ field: { value, onChange, ref, ...field } }) => {
            return (
              <Select
                size="compact"
                options={options}
                value={[{ id: value }]}
                onChange={(params) => {
                  onChange(params.value[0].typeName);
                }}
                clearable={false}
                inputRef={ref}
                {...field}
                searchable={false}
                getValueLabel={({ option }) => {
                  const workspaceType = options.find((item) => item.typeName === option.id);
                  // @ts-expect-error
                  return <WorkspaceLabel workspaceType={workspaceType} />;
                }}
                getOptionLabel={(option) => {
                  return (
                    <WorkspaceLabel
                      workspaceType={option.option as WorkSpaceType}
                      disabled={option.optionState.$disabled}
                    />
                  );
                }}
              />
            );
          }}
        />
      </FormControl>
      <ParagraphLabel
        className={css({
          color: theme.colors['text.secondary'],
        })}
      >
        {scheduleDescription(getValues())}
      </ParagraphLabel>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '8px',
        })}
      >
        <Button kind="secondary" type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button
          kind="secondary"
          type="button"
          disabled={mutationAdd.isLoading || mutationEdit.isLoading}
          isLoading={mutationAdd.isLoading || mutationEdit.isLoading}
          onClick={handleSubmit(async (data) => {
            if (editSchedule) {
              onUpdate(data);
            } else {
              onCreate(data);
            }
          })}
          overrides={{
            BaseButton: {
              style: {
                ...expand({
                  borderColor: theme.colors['background.brand.bold'],
                }),
              },
            },
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

function NoRepeat({ form }: { form: UseFormReturn<ScheduleData, any, undefined> }) {
  const [css] = useStyletron();
  const { control, watch } = form;

  const repeat = watch('repeat');
  if (repeat !== 'NOREPEAT') {
    return null;
  }

  return (
    <>
      <div
        className={css({
          flex: 1,
        })}
      >
        <FormControl label="Date">
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, ref, ...field } }) => {
              return (
                <DatePicker
                  formatString={'yyyy/MM/dd'}
                  placeholder="YYYY/MM/DD"
                  onChange={({ date }) => {
                    onChange(date);
                  }}
                  {...field}
                  highlightedDate={new Date()}
                  size="compact"
                />
              );
            }}
          />
        </FormControl>
      </div>
      <div
        className={css({
          flex: 1,
        })}
      >
        <FormControl label="Time">
          <Controller
            control={control}
            name="time"
            render={({ field: { onChange, ref, ...field } }) => {
              return (
                <TimePicker
                  onChange={(date) => {
                    onChange(date);
                  }}
                  {...field}
                  size="compact"
                  format="24"
                  step={30 * 60}
                />
              );
            }}
          />
        </FormControl>
      </div>
    </>
  );
}

function DailyOrWeekday({ form }: { form: UseFormReturn<ScheduleData, any, undefined> }) {
  const [css] = useStyletron();
  const { control, watch } = form;

  const repeat = watch('repeat');
  if (repeat !== 'DAILY' && repeat !== 'WEEKDAY') {
    return null;
  }

  return (
    <>
      <div
        className={css({
          flex: 1,
        })}
      >
        <FormControl label="Time">
          <Controller
            control={control}
            name="time"
            render={({ field: { onChange, ref, ...field } }) => {
              return (
                <TimePicker
                  onChange={(date) => {
                    onChange(date);
                  }}
                  {...field}
                  size="compact"
                  format="24"
                  step={30 * 60}
                />
              );
            }}
          />
        </FormControl>
      </div>
    </>
  );
}

function Weekly({ form }: { form: UseFormReturn<ScheduleData, any, undefined> }) {
  const [css] = useStyletron();
  const {
    control,
    watch,
    formState: { errors },
  } = form;

  const repeat = watch('repeat');
  if (repeat !== 'WEEKLY') {
    return null;
  }

  return (
    <>
      <div
        className={css({
          flex: 2,
        })}
      >
        <FormControl label="Repeat on" error={(errors as FieldErrors<ScheduleWeekly>).repeat_on?.message}>
          <Controller
            control={control}
            name="repeat_on"
            render={({ field: { value, onChange, ref, ...field } }) => {
              return (
                <Select
                  error={!!(errors as FieldErrors<ScheduleWeekly>).repeat_on}
                  size="compact"
                  overrides={{
                    // tweak tag style so it will not change height when add tag to select input.
                    Tag: {
                      props: {
                        overrides: {
                          Text: {
                            style: {
                              lineHeight: '12px',
                            },
                          },
                          Root: {
                            style: {
                              paddingTop: 0,
                              paddingBottom: 0,
                              height: '16px',
                              marginTop: '2px',
                              marginBottom: '2px',
                            },
                          },
                        },
                      },
                    },
                  }}
                  options={WeekDays}
                  value={value.map((v) => ({ id: v }))}
                  labelKey="label"
                  onChange={(params) => {
                    onChange(params.value.map((v) => v.id));
                  }}
                  clearable={true}
                  inputRef={ref}
                  {...field}
                  searchable={false}
                  multi={true}
                  closeOnSelect={false}
                />
              );
            }}
          />
        </FormControl>
      </div>
      <div
        className={css({
          flex: 1,
        })}
      >
        <FormControl label="Time">
          <Controller
            control={control}
            name="time"
            render={({ field: { onChange, ref, ...field } }) => {
              return (
                <TimePicker
                  onChange={(date) => {
                    onChange(date);
                  }}
                  {...field}
                  size="compact"
                  format="24"
                  step={30 * 60}
                />
              );
            }}
          />
        </FormControl>
      </div>
    </>
  );
}

const WorkspaceLabel = ({ workspaceType, disabled }: { workspaceType?: WorkSpaceType; disabled?: boolean }) => {
  const [css, theme] = useStyletron();
  if (!workspaceType) {
    return null;
  }
  const content = (
    <ParagraphLabel
      className={css({
        opacity: disabled ? 0.5 : 1,
      })}
    >
      <span
        className={css({
          color: theme.colors['dropdown.text'],
          fontWeight: 500,
        })}
      >
        {workspaceType.typeName}
      </span>
      {workspaceType.typeName === 'Pause' ? null : (
        <span
          className={css({
            color: theme.colors['text.secondary'],
          })}
        >{` (vCPUs: ${workspaceType.cpu}, RAM: ${workspaceType.memory}, Cost: ${formatPricePerHour(
          currency(workspaceType.cost_per_hour / 100)
        )})`}</span>
      )}
    </ParagraphLabel>
  );

  if (!disabled) {
    return content;
  }

  return (
    <StatefulTipsPopover
      content="The size is not available within your organization's current quota. Please contact support to request an expansion."
      triggerType={TRIGGER_TYPE.hover}
      focusLock={true}
      overrides={{
        Body: {
          style: {
            maxWidth: '450px',
          },
        },
      }}
    >
      {content}
    </StatefulTipsPopover>
  );
};

const formControlOverrides: FormControlOverrides = {
  Label: {
    style: ({ $theme }) => {
      const theme = $theme as CustomTheme;
      return {
        fontSize: '12px',
        lineHeight: '16px',
        color: theme.colors['dropdown.text'],
        marginTop: '-8px',
        marginBottom: '-4px',
      };
    },
  },
  ControlContainer: {
    style: {
      marginBottom: '8px',
    },
  },
};

export function FormControl({ overrides, error, ...props }: FormControlProps) {
  return (
    <BaseFormControl
      {...props}
      error={error || undefined}
      overrides={mergeOverrides(formControlOverrides as Overrides<unknown>, overrides as Overrides<unknown>)}
    />
  );
}
